






















import { contentStructure } from '@/content/contentStructure';
import { Post } from '@/types/interface';
import Vue from 'vue';

export default Vue.extend({
  name: 'Post',
  props: ['postId'],
  data() {
    return {
      postComponent: null,
      postData: {} as Post,
    };
  },
  async created() {
    await this.loadPost();
    await this.getPostByImageName(this.postId);
  },
  watch: {
    postId: {
      handler(newPostId) {
        this.loadPost();
        this.getPostByImageName(newPostId);
      },
      immediate: true, // Ensures this is run when the component is mounted
    },
  },
  methods: {
    async loadPost() {
      try {
        // Dynamically import the MDX file based on the postId prop
        const component = await import(`../content/${this.postId}.mdx`);
        this.postComponent = component.default;
      } catch (error) {
        console.error('Error loading post:', error);
      }
    },
    async getPostByImageName(imageName: string) {
      for (const category of contentStructure.categories) {
        for (const post of category.posts) {
          if (post.img.name === imageName) {
            return (this.postData = post);
          }
        }
      }
      return null;
    },
  },
});
